<template>
	<div style="text-align: center;background-color: #EBEEF5;">
		<div class="sameWidth">
			<el-breadcrumb separator-class="el-icon-arrow-right" style="padding:20px 20px;">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/police' }">政策法规</el-breadcrumb-item>
				<el-breadcrumb-item>法规详情</el-breadcrumb-item>
			</el-breadcrumb>
			<div style="text-align: left;padding:40px;margin:10px;background-color: #fff;">
				<div style="margin-left: 40px;">
					<div id="tenderDiv">
						<h3 style="text-align: center;">{{info.title}}</h3>
						<h4 style="text-align: center;color:#909399;"><span
								style="margin-right: 50px;" v-if="info.issue_date !='0000-00-00'">{{info.issue_date}}</span></h4>
					</div>
					<div v-html="info.content_html" id="wangTable">
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<style>
	#wangTable img {
		max-width: 100%;
	}

	#wangTable table {
		width: 100%;
		border-top: #DCDFE6 1px solid;
		border-left: #DCDFE6 1px solid;
	}

	#wangTable table td,
	#wangTable table th {
		border-bottom: #DCDFE6 1px solid;
		border-right: #DCDFE6 1px solid;
		padding: 10px;
		height: 25px;
		line-height: 25px;
	}
</style>
<script>
	export default {
		name:'policeContent',
		data() {
			return {
				screenWidth: 1300,
				info:{
					title:'',
					issue_date:'',
					content_html:''
				}
			};
		},
		mounted() {
			this.bus.$emit('headerImg', [{
				src: require('@/assets/images/bk/政策法规.jpg'),
				href: ""
			}]);
			var that = this;
			this.$axios.post(this.baseUrl + 'index/pdicies/getPdicies?id=' + this.$route.query.id).then(
				response => {
					console.log(response);
					that.info.title = response.data.title;
					that.info.issue_date = response.data.issue_date;
					that.info.content_html = response.data.content_html;
				}).catch(function(error) {
				window.console.log(error)
			}); //ajax请求失败
			this.screenWidth = document.documentElement.clientWidth;
			window.addEventListener('resize', () => {
				this.screenWidth = document.documentElement.clientWidth;
			})
		},
		methods: {

		},
	};
</script>
